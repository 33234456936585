<template>
  <div>
      <div class="slider-wrapper desktop">
        <div class="prev" @click="reduceIndex()" :class="{ 'disabled' : index == 1 }">
          <img src="@/assets/prev.png" />
        </div>

        <div class="slider-imgs">
          <div class="slider-img winner-1" v-show="index === 1">
          </div>

          <div class="slider-img winner-2" v-show="index === 1">
          </div>
          
          <div class="slider-img winner-3" v-show="index === 1">
          </div>

          <div class="slider-img winner-4" v-show="index === 1">
          </div>

          <div class="slider-img winner-5" v-show="index === 2">
          </div>

          <div class="slider-img winner-6" v-show="index === 2">
          </div>

          <div class="slider-img winner-7" v-show="index === 2">
          </div>

          <div class="slider-img winner-8" v-show="index === 2">
          </div>

          <div class="slider-img winner-9" v-show="index === 3">
          </div>

          <div class="slider-img winner-10" v-show="index === 3">
          </div>

          <div class="slider-img winner-11" v-show="index === 3">
          </div>

          <div class="slider-img winner-12" v-show="index === 3">
          </div>

          <div class="slider-img winner-13" v-show="index === 4">
          </div>

          <div class="slider-img winner-14" v-show="index === 4">
          </div>

          <div class="slider-img winner-15" v-show="index === 4">
          </div>

          <div class="slider-img winner-16" v-show="index === 4">
          </div>

          <div class="slider-img winner-17" v-show="index === 5">
          </div>

          <div class="slider-img winner-18" v-show="index === 5">
          </div>

          <div class="slider-img winner-19" v-show="index === 5">
          </div>

          <div class="slider-img winner-20" v-show="index === 5">
          </div>

          <div class="slider-img winner-21" v-show="index === 6">
          </div>

          <div class="slider-img winner-22" v-show="index === 6">
          </div>

          <div class="slider-img winner-23" v-show="index === 6">
          </div>

          <div class="slider-img winner-24" v-show="index === 6">
          </div>

          <div class="slider-img winner-25" v-show="index === 7">
          </div>

          <div class="slider-img winner-26" v-show="index === 7">
          </div>

          <div class="slider-img winner-27" v-show="index === 7">
          </div>

          <div class="slider-img winner-28" v-show="index === 7">
          </div>

          <div class="slider-img winner-29" v-show="index === 8">
          </div>

          <div class="slider-img winner-30" v-show="index === 8">
          </div>

          <div class="slider-img winner-31" v-show="index === 8">
          </div>

          <div class="slider-img winner-32" v-show="index === 8">
          </div>

          <div class="slider-img winner-33" v-show="index === 9">
          </div>

          <div class="slider-img winner-34" v-show="index === 9">
          </div>

          <div class="slider-img winner-35" v-show="index === 9">
          </div>

          <div class="slider-img winner-36" v-show="index === 9">
          </div>
        </div>

        <div class="next" @click="addIndex()" :class="{ 'disabled' : index > 8 }">
          <img src="@/assets/next.png" />
        </div>
      </div>

      <div class="slider-wrapper tablet">
        <div class="prev" @click="reduceIndex()" :class="{ 'disabled' : index == 1 }">
          <img src="@/assets/prev.png" />
        </div>

        <div class="slider-imgs">
          <div class="slider-img winner-1" v-show="index === 1">
          </div>

          <div class="slider-img winner-2" v-show="index === 1">
          </div>
          
          <div class="slider-img winner-3" v-show="index === 2">
          </div>

          <div class="slider-img winner-4" v-show="index === 2">
          </div>
          
          <div class="slider-img winner-5" v-show="index === 3">
          </div>

          <div class="slider-img winner-6" v-show="index === 3">
          </div>
          
          <div class="slider-img winner-7" v-show="index === 4">
          </div>

          <div class="slider-img winner-8" v-show="index === 4">
          </div>
          
          <div class="slider-img winner-9" v-show="index === 5">
          </div>

          <div class="slider-img winner-10" v-show="index === 5">
          </div>
          
          <div class="slider-img winner-11" v-show="index === 6">
          </div>

          <div class="slider-img winner-12" v-show="index === 6">
          </div>
          
          <div class="slider-img winner-13" v-show="index === 7">
          </div>

          <div class="slider-img winner-14" v-show="index === 7">
          </div>

          <div class="slider-img winner-15" v-show="index === 8">
          </div>

          <div class="slider-img winner-16" v-show="index === 8">
          </div>

          <div class="slider-img winner-17" v-show="index === 9">
          </div>

          <div class="slider-img winner-18" v-show="index === 9">
          </div>

          <div class="slider-img winner-19" v-show="index === 10">
          </div>

          <div class="slider-img winner-20" v-show="index === 10">
          </div>

          <div class="slider-img winner-21" v-show="index === 11">
          </div>

          <div class="slider-img winner-22" v-show="index === 11">
          </div>

          <div class="slider-img winner-23" v-show="index === 12">
          </div>

          <div class="slider-img winner-24" v-show="index === 12">
          </div>

          <div class="slider-img winner-25" v-show="index === 13">
          </div>

          <div class="slider-img winner-26" v-show="index === 13">
          </div>

          <div class="slider-img winner-27" v-show="index === 14">
          </div>

          <div class="slider-img winner-28" v-show="index === 14">
          </div>

          <div class="slider-img winner-29" v-show="index === 15">
          </div>

          <div class="slider-img winner-30" v-show="index === 15">
          </div>

          <div class="slider-img winner-31" v-show="index === 16">
          </div>

          <div class="slider-img winner-32" v-show="index === 16">
          </div>

          <div class="slider-img winner-33" v-show="index === 17">
          </div>

          <div class="slider-img winner-34" v-show="index === 17">
          </div>

          <div class="slider-img winner-35" v-show="index === 18">
          </div>

          <div class="slider-img winner-36" v-show="index === 18">
          </div>
        </div>

        <div class="next" @click="addIndex()" :class="{ 'disabled' : index > 17 }">
          <img src="@/assets/next.png" />
        </div>
      </div>

      <div class="slider-wrapper mobile">
        <!-- <div class="prev" @click="reduceIndex()" :class="{ 'disabled' : index == 1 }">
          <img src="@/assets/prev.png" />
        </div> -->

        <div class="slider-imgs">
          <div class="prev" @click="reduceIndex()" :class="{ 'disabled' : index == 1 }">
            <img src="@/assets/prev.png" />
          </div>

          <div class="slider-img winner-1" v-show="index === 1">
          </div>

          <div class="slider-img winner-2" v-show="index === 2">
          </div>

          <div class="slider-img winner-3" v-show="index === 3">
          </div>

          <div class="slider-img winner-4" v-show="index === 4">
          </div>

          <div class="slider-img winner-5" v-show="index === 5">
          </div>

          <div class="slider-img winner-6" v-show="index === 6">
          </div>

          <div class="slider-img winner-7" v-show="index === 7">
          </div>

          <div class="slider-img winner-8" v-show="index === 8">
          </div>

          <div class="slider-img winner-9" v-show="index === 9">
          </div>

          <div class="slider-img winner-10" v-show="index === 10">
          </div>

          <div class="slider-img winner-11" v-show="index === 11">
          </div>

          <div class="slider-img winner-12" v-show="index === 12">
          </div>

          <div class="slider-img winner-13" v-show="index === 13">
          </div>

          <div class="slider-img winner-14" v-show="index === 14">
          </div>

          <div class="slider-img winner-15" v-show="index === 15">
          </div>

          <div class="slider-img winner-16" v-show="index === 16">
          </div>

          <div class="slider-img winner-17" v-show="index === 17">
          </div>

          <div class="slider-img winner-18" v-show="index === 18">
          </div>

          <div class="slider-img winner-19" v-show="index === 19">
          </div>

          <div class="slider-img winner-20" v-show="index === 20">
          </div>

          <div class="slider-img winner-21" v-show="index === 21">
          </div>

          <div class="slider-img winner-22" v-show="index === 22">
          </div>

          <div class="slider-img winner-23" v-show="index === 23">
          </div>

          <div class="slider-img winner-24" v-show="index === 24">
          </div>

          <div class="slider-img winner-25" v-show="index === 25">
          </div>

          <div class="slider-img winner-26" v-show="index === 26">
          </div>

          <div class="slider-img winner-27" v-show="index === 27">
          </div>

          <div class="slider-img winner-28" v-show="index === 28">
          </div>

          <div class="slider-img winner-29" v-show="index === 29">
          </div>

          <div class="slider-img winner-30" v-show="index === 30">
          </div>

          <div class="slider-img winner-31" v-show="index === 31">
          </div>

          <div class="slider-img winner-32" v-show="index === 32">
          </div>

          <div class="slider-img winner-33" v-show="index === 33">
          </div>

          <div class="slider-img winner-34" v-show="index === 34">
          </div>

          <div class="slider-img winner-35" v-show="index === 35">
          </div>

          <div class="slider-img winner-36" v-show="index === 36">
          </div>

          <div class="next" @click="addIndex()" :class="{ 'disabled' : index > 35 }">
            <img src="@/assets/next.png" />
          </div>
        </div>

        <!-- <div class="next" @click="addIndex()" :class="{ 'disabled' : index > 3 }">
          <img src="@/assets/next.png" />
        </div> -->
      </div>
  </div>
</template>

<script>

export default {
  name: 'Slider',

  data: () => ({
    index: 1
  }),

  computed: {
  },

  methods: {
    addIndex(){
      this.index++
    },

    reduceIndex(){
      this.index--
    }
  }
}
</script>

<style scoped lang="scss">
.desktop {
  @media(max-width: 1100px){
    display: none !important;
  }
}

.tablet {
  display: none !important;

  @media(max-width: 1100px) {
    display: flex !important;
  }

  @media(max-width: 768px) {
    display: none !important;
  }
}

.mobile {
  display: none !important;

  @media(max-width: 768px){
    display: flex !important;
  }
}

.slider-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}

.slider-imgs {
  display: flex;
  max-width: 97%;
  width: 100%;
  position: relative;
  justify-content: space-around;

  @media(max-width: 768px) {
    width: fit-content;
  }
}

.slider-img {
  border-radius: 20px;
  height: 330px;
  width: 330px;
  background-size: cover;
  background-position: center center;
  // background-image: url('@/assets/winner-1.jpg');

  @media(max-width: 1599px) {
    height: 280px;
    width: 280px;
  }

  @media(max-width: 1400px) {
    height: 235px;
    width: 235px;
  }

  @media(max-width: 1200px) {
    height: 200px;
    width: 200px;
  }

  @media(max-width: 1099px) {
    height: 280px;
    width: 280px;
  }

  @media(max-width: 768px) {
    margin: 0 15px;
  }
}

.prev, .next {
  position: absolute;
  border-radius: 50%;
  font-weight: 900;
  color: white;
  max-width: 35px;
  max-height: 35px;
  top: 45%;
  cursor: pointer;
  z-index: 999;

  img {
    max-width: 35px;
    max-height: 35px;
  }
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

.winner-1 {
  background-image: url('@/assets/winner-1.jpeg');
}

.winner-2 {
  background-image: url('@/assets/winner-2.jpeg');
}

.winner-3 {
  background-image: url('@/assets/winner-3.jpg');
}

.winner-4 {
  background-image: url('@/assets/winner-4.jpg');
}

.winner-5 {
  background-image: url('@/assets/winner-5.jpg');
}

.winner-6 {
  background-image: url('@/assets/winner-6.jpg');
}

.winner-7 {
  background-image: url('@/assets/winner-7.jpg');
}

.winner-8 {
  background-image: url('@/assets/winner-8.jpg');
}

.winner-9 {
  background-image: url('@/assets/winner-9.jpg');
}

.winner-10 {
  background-image: url('@/assets/winner-10.jpg');
}

.winner-11 {
  background-image: url('@/assets/winner-11.jpg');
}

.winner-12 {
  background-image: url('@/assets/winner-12.jpg');
}

.winner-13 {
  background-image: url('@/assets/winner-13.jpg');
}

.winner-14 {
  background-image: url('@/assets/winner-14.jpg');
}

.winner-15 {
  background-image: url('@/assets/winner-15.jpg');
}

.winner-16 {
  background-image: url('@/assets/winner-16.jpg');
}

.winner-17 {
  background-image: url('@/assets/winner-17.jpg');
}

.winner-18 {
  background-image: url('@/assets/winner-18.jpg');
}

.winner-19 {
  background-image: url('@/assets/winner-19.jpg');
}

.winner-20 {
  background-image: url('@/assets/winner-20.jpg');
}

.winner-21 {
  background-image: url('@/assets/winner-21.jpg');
}

.winner-22 {
  background-image: url('@/assets/winner-22.jpg');
}

.winner-23 {
  background-image: url('@/assets/winner-23.jpg');
}

.winner-24 {
  background-image: url('@/assets/winner-24.jpg');
}

.winner-25 {
  background-image: url('@/assets/winner-25.jpg');
}

.winner-26 {
  background-image: url('@/assets/winner-26.jpg');
}

.winner-27 {
  background-image: url('@/assets/winner-27.jpg');
}

.winner-28 {
  background-image: url('@/assets/winner-28.jpg');
}

.winner-29 {
  background-image: url('@/assets/winner-29.jpg');
}

.winner-30 {
  background-image: url('@/assets/winner-30.jpg');
}

.winner-31 {
  background-image: url('@/assets/winner-31.jpg');
}

.winner-32 {
  background-image: url('@/assets/winner-32.jpg');
}

.winner-33 {
  background-image: url('@/assets/winner-33.jpg');
}

.winner-34 {
  background-image: url('@/assets/winner-34.jpg');
}

.winner-35 {
  background-image: url('@/assets/winner-35.jpg');
}

.winner-36 {
  background-image: url('@/assets/winner-36.jpg');
}

.disabled {
  background: $light-grey;
  pointer-events: none;
  filter: grayscale(100%);
}
</style>
